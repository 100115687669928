<template>
  <div class="subject_warp">
    <div class="form">
      <div class="date">
        <span>
          教师改卷数统计
        </span>
        <el-date-picker v-model="date"
                        style="margin:10px 0"
                        @change="clear"
                        @focus="$forbid"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <span class="fanwei">
          范围
        </span>
        <!-- 默认7天 -->
        <el-select style="width:110px"
                   v-model="scope"
                   @change="clear"
                   clearable
                   placeholder="范围">
          <el-option v-for="item in $parent.scopeList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="subject">
        <div class="teacher"
             v-if="$parent.home_id == 2 || $parent.home_id == 1">
          <el-select style="width:110px"
                     v-model="assist_id"
                     @change="getHomeSubject"
                     clearable
                     filterable
                     placeholder="选择教师">
            <el-option v-for="item in $parent.teacherList"
                       :key="item.edu_user_id"
                       :label="item.edu_user_name"
                       :value="item.edu_user_id">
            </el-option>
          </el-select>
        </div>
        <div class="select">
          <el-select style="width:130px"
                     v-model="subject_id"
                     @change="clear"
                     clearable
                     placeholder="科目">
            <el-option v-for="item in subjectList"
                       :key="item.subject_id + ''"
                       :label="item.subject_name"
                       :value="item.subject_id + '' ">
            </el-option>
          </el-select>
        </div>
        <span class="subject_list"
              style="margin:10px 0;">
          <span :class="{'item':true,'isActive':item.subject_id == subject_id}"
                @click="clickItem(item.subject_id)"
                v-for="item in subjectList"
                :key="item.subject_id">{{item.subject_name}}</span>
        </span>
      </div>
    </div>
    <div id="myChartTeacherPaper"
         :style="{width: '100%', height: '40vh'}"></div>
  </div>
</template>

<script>
import { frontDay, frontNowDay } from '@/utils/date.js'
export default {
  data () {
    return {
      // date: [frontDay(7, false), frontNowDay(0, false)],
      date: [],
      teacher: '',
      scope: 7,
      assist_id: '',
      subject_id: '',
      teacherList: [],
      label: [],
      value: [],
      subjectList: []
    }
  },
  mounted () {
    this.getHomeSubject()
  },
  methods: {
    clear () {
      let myChart = this.$echarts.init(document.getElementById('myChartTeacherPaper'))
      myChart.dispose()
      this.getRuoDian()
    },
    drawLine () {
      var myChart = this.$echarts.init(document.getElementById('myChartTeacherPaper'));
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: 'category',
          data: this.label
        },
        dataZoom: {
          type: "slider",
          show: true
        },
        grid: {
          bottom: '20%',
          top: '15%',
          left: '10%',
          right: '10%'

        },
        yAxis: {
          type: 'value',
          min: 0
        },
        color: ['#3F9EFF'],
        series: [
          {
            data: this.value,
            type: 'bar'
          }
        ]
      });
    },
    getSubjectList () {
      this.getRuoDian()
    },
    getRuoDian () {
      let params = {
        subject_id: this.subject_id,
        school_id: this.$parent.school_id,
        assist_id: this.assist_id,
        scope: this.scope,
      }
      if (this.date) {
        params.start_time = this.date[0]
        params.end_time = this.date[1]
      }
      this.$http({
        url: '/api/v1/main/get_teacher_judge_data',
        method: 'get',
        params
      }).then(res => {
        let value = []
        let label = []
        res.data.list.forEach(item => {
          label.push(`${item.start_time}~${item.end_time}`)
          value.push(Number(item.count))
        })
        this.value = value
        this.label = label

        this.$nextTick(() => {
          this.drawLine()
        })
      })
    },
    clickItem (id) {
      if (this.subject_id == id) {
        this.subject_id = ''
        this.clear()
        return
      }
      this.subject_id = id
      this.clear()
    },
    getHomeSubject () {
      this.$http({
        url: 'api/v1/main/home_subject',
        method: 'get',
        params: {
          edu_user_id: this.assist_id,
        }
      }).then(res => {
        this.subjectList = res.data.list
        this.subject_id = res.data.list[0].subject_id + ''
        this.clear()
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .isActive {
    border: 1px solid #3f9eff !important;
    color: #3f9eff !important;
  }
  .subject_warp {
    background: white;
    padding: 10px;
    margin: 1%;
  }
  .subject_list {
    border: 1px solid #d9d9d9;
    display: inline-block;
    span {
      display: inline-block;
      border-right: 1px solid #d9d9d9;
      padding: 5px 10px;
      cursor: pointer;
      color: #000000;
    }
  }
  .form {
    display: flex;
    .date {
      flex: 1;
      span {
        width: 120px;
        font-weight: bold;
        color: rgba(8, 8, 8, 0.85);
        font-size: 16px;
      }
    }
    .subject {
      display: flex;
      align-items: center;
      .teacher {
        margin-right: 10px;
      }
    }
  }
  ::v-deep.el-range-editor.el-input__inner {
    width: 230px !important;
  }
  .select {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .subject_warp {
    background: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 1%;
    .form {
      display: flex;
      flex-wrap: wrap;
      .date {
        flex: 2;
        width: 100%;
        display: flex;
        align-items: center;
        .fanwei {
          font-size: 14px;
          font-weight: 500;
          color: #666666;
          display: inline-block;
          margin: 0 10px;
        }
        span {
          &:nth-child(1) {
            display: inline-block;
            width: 112px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(8, 8, 8, 0.85);
            margin-right: 5px;
          }
        }
        ::v-deep .el-date-editor--daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
          width: 230px;
        }
      }
      .subject {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .subject_list {
          display: none;
        }
        .teacher {
          margin-right: 10px;
        }
      }
    }
  }
  #myChartTeacherPaper {
    height: 350px !important;
  }
}
</style>